import { useRef, useEffect, useState } from 'react'
import NET from 'vanta/dist/vanta.net.min'
import * as THREE from 'three'

const useVanta = () => {
    const myRefDiv = useRef(null)
    const [vanta, setVanta] = useState(0)

    useEffect(() => {
        if (!vanta) {
            setVanta(NET({
                THREE,
                el: myRefDiv.current,
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                minHeight: 200.00,
                minWidth: 200.00,
                scale: 1.00,
                scaleMobile: 1.00,
                color: "pink",
                backgroundColor: 0xdcdcdc,
                lineColors: "black",

            }))
        }
        return () => {
            if (vanta) {
                vanta.destroy()
            }
        }
    }, [vanta])

    return myRefDiv
}

export default useVanta