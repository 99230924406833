import React from 'react';
import { Link } from 'react-router-dom';
import AboutSection from './AboutSection';


const About = () => {

    return (
        <div className="bg-gray-100">

            <div className="bg-blue-500 py-4 about-banner flex justify-center items-center ">
                <h1 className="flex text-center text-4xl text-white justify-center items-center mx-auto">Lawyer, Developer & Entrepreneur</h1>

            </div>

            <div className="">

                <AboutSection
                    imageSide="right"
                    background="white"
                    image="../scotus.jpg"
                    title="Legal Experience"
                    paragraphs={[
                        "In 2009, I graduated with honors from Georgetown University Law Center, and then clerked for an exceptional federal judge. From 2010-2013, I was an attorney in the Newport Beach, California office of Irell & Manella LLP, and helped win business and intellectual property cases.",
                        "In 2013, I founded a law firm in Irvine, CA that eventually grew to 5 attorneys. As lead counsel, I won hotly disputed cases in both state and federal court, obtained excellent settlements, and negotiated numerous transactions. For plaintiffs, I recovered millions of dollars in contested cases. For defendants, I obtained defense judgments and settlements for a fraction of the amount sought."
                    ]}
                />

                <AboutSection
                    imageSide="left"
                    background="gray"
                    image="../IMG_9291.jpg"
                    title="Deeply Learning"
                    paragraphs={[
                        "In 2017, I handed the law firm to my partners and moved to New York to concentrate on software development, with a focus on machine learning, artificial intelligence, and natural language processing/understanding. I received a Coursera deep learning specialist certification, and began exploring methods to improve human-computer interaction, automate repetitive tasks, and understand language.",
                        "I follow state-of-the-art research in these areas, with a particular interest in: using human behavior as computer input; grounded language understanding; multi-hop reasoning; and multi-modal AI."
                    ]}
                />

                <AboutSection
                    imageSide="right"
                    background="white"
                    image="../IMG_9589.jpg"
                    title="AI and Law"
                    paragraphs={[
                        "In 2023, I rejoined my law firm partners at Lopez, Bark & Schulz LLP. My legal work focuses on technology and insurance law, with an aspiration to help creators, innovators, and entrepreneurs. I have unique expertise in AI, machine learning, and data privacy, having worked in the trenches on all aspects of machine learning, from data collection and cleaning to training models to app deployment.",
                        "I'm also running a company that develops collaborative AI-powered digital assistants and user interface software. With my software work, I'm using AI and automation to improve the productivity and make human-computer interaction more seamless, intuitive, and dazzling. ",
                    ]}
                />

                <AboutSection
                    imageSide="left"
                    background="gray"
                    image="../IMG_6866.jpg"
                    title="Spotlight on Ethics"
                    paragraphs={[
                        "In both tech and law, I've devoted much of my career to ethics. I served on the Georgetown Journal of Legal Ethics in law school, then as the Secretary of the Orange County Bar Association Professionalism and Ethics Committee. Further, as counsel for clients, I have obtained multiple victories in ethics disputes. I've advised companies on compliance with privacy regulations and web accessibility standards, and written about the need for AI to align with human values"
                    ]}
                />

                {/* don't use the ABoutSection prop, keep the text, no image, and center it all fully */}
                {/* <div className="bg-white py-4 justify-center items-center">
                    <div className="container mx-auto px-8 ">
                        <div className="flex flex-wrap justify-center items-center">
                            <div className="w-3/4">
                                <h1 className="text-xl md:text-3xl font-bold text-gray-900 pb-4">Spotlight on Ethics</h1>
                                <p className="pb-4 text-sm md:text-base">In both tech and law, I've devoted much of my career to ethics. I served on the Georgetown Journal of Legal Ethics in law school, then as the Secretary of the Orange County Bar Association Professionalism and Ethics Committee. Further, as counsel for clients, I have obtained multiple victories in ethics disputes. I've advised companies on compliance with privacy regulations and web accessibility standards, and written about the need for AI to align with human values</p>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </div>
    )
}

export default About;