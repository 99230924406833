import React from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes, FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isMenuOpen: false };
    }

    render() {
        return (
            // <nav className="bg-gray-900 ">
            // same but make its z value always alwyas always on top
            <nav className="bg-gray-900 z-100000050 ">
                <div className="flex  justify-between px-4 py-4 joenavbar max-w-screen-2xl mx-auto px-8">
                    <Link to="/" className="text-2xl h-full align-top font-medium text-gray-100">JOE WILBERT</Link>
                    <div className="hidden md:flex">
                        {/* <Link to="/meet" className="ml-4 px-3 py-2 rounded-md text-md font-medium leading-5 text-gray-100 hover:bg-gray-700 focus:outline-none focus:bg-gray-700">Consult</Link> */}
                        <Link to="/about" className="ml-4 px-3 py-2 rounded-md text-md font-medium leading-5 text-gray-100 hover:bg-gray-700 focus:outline-none focus:bg-gray-700">About</Link>
                        {/* <Link to="/tech" className="ml-4 px-3 py-2 rounded-md text-md font-medium leading-5 text-gray-100 hover:bg-gray-700 focus:outline-none focus:bg-gray-700">Tech</Link>
                        <Link to="/law" className="ml-4 px-3 py-2 rounded-md text-md font-medium leading-5 text-gray-100 hover:bg-gray-700 focus:outline-none focus:bg-gray-700">Law</Link> */}

                        <a href="https://twitter.com/joe_wilbert" target="_blank" rel="noopener noreferrer" className="ml-4 px-3 py-2 rounded-md text-xl font-medium leading-5 text-gray-100 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"><FaTwitter /></a>
                        {/* add linkedin */}
                        <a href="https://linkedin.com/in/joewilbert" target="_blank" rel="noopener noreferrer" className="ml-4 px-3 py-2 rounded-md text-xl font-medium leading-5 text-gray-100 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"><FaLinkedin /></a>
                        <a href="https://github.com/joewilbert" target="_blank" rel="noopener noreferrer" className="ml-4 px-3 py-2 rounded-md text-xl font-medium leading-5 text-gray-100 hover:bg-gray-700 focus:outline-none focus:bg-gray-700"><FaGithub /></a>
                    </div>
                    <div className="md:hidden ">
                        <button
                            onClick={this.props.handleClick}
                            className="px-3 py-2 rounded-md text-black hover:bg-gray-700 focus:outline-none w-full items-end content-end burger-x"
                        >
                            {/* {this.props.isMenuOpen ? <FaTimes className="float-right" /> : <FaBars />} */}
                            {/* same but make the icons gray-100 */}
                            {this.props.isMenuOpen ? <FaTimes className="float-right text-gray-100" /> : <FaBars className="text-gray-100" />}
                        </button>
                        {this.props.isMenuOpen && (
                            <div className="md:hidden items-end content-end">
                                <div className="block px-2 pt-2 pb-3">
                                    {/* <Link to="/meet" className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white text-right focus:bg-gray-800">Meet</Link> */}
                                    {/* same but when clicked set isMenuOpen to false */}
                                    {/* <Link to="/meet" className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white text-right focus:bg-gray-800" onClick={() => this.props.handleClick()}>Consult</Link> */}
                                    <Link to="/about" className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white text-right focus:bg-gray-800" onClick={() => this.props.handleClick()}>About</Link>
                                    {/* <Link to="/tech" className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white text-right focus:bg-gray-800" onClick={() => this.props.handleClick()}>Tech</Link> */}
                                    {/* <Link to="/law" className="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white text-right focus:bg-gray-800" onClick={() => this.props.handleClick()}>Law</Link> */}
                                </div>
                            </div>
                        )}
                    </div>


                </div>
                {this.state.isMenuOpen && (
                    <div className="md:hidden">
                    </div>
                )}
            </nav>
        );
    }
}

export default Navbar;