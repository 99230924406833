// terms and conditions component 
function Terms() {
    return (
        <div className="flex flex-col items-center justify-center px-4">
            <h1 className="text-3xl font-medium py-8">Terms and Conditions of Website Use</h1>
            <div className="flex flex-col items-center justify-center max-w-4xl space-y-4">
                <p className="text-lg font-medium">OVERVIEW</p>
                <p className="text-md">This site is operated by the Joseph R. Wilbert, a sole practitioner attorney licensed in the State of California (the “Firm”). Please read these Terms of Use carefully before using this Site. By using the Site, you agree to be bound by these Terms of Use. The Firm reserves the right to modify these Terms of Use at any time by posting modified Terms of Use, and your continued use of the Site thereafter will constitute agreement with such modifications.</p>
                <p className="text-lg font-medium">NO ATTORNEY CLIENT RELATIONSHIP</p>
                <p className="text-md">Transmission of the information in the Firm’s web site is not intended to create, and receipt does not constitute, an attorney-client relationship. Newsletter recipients and online readers should not rely upon the transmission of an e-mail message to the Firm through this web site to create an attorney-client relationship. Internet subscribers and online readers should not act upon any information in this web site without first consulting legal counsel of their own directly. The hiring of a lawyer is an important decision that should not be based solely upon advertisements. Before selection of counsel, users should ask a prospective attorney or law firm to send free written information about their qualifications and experience. The information in this web site is not provided in the course of an attorney-client relationship and is not intended to constitute legal advice or to substitute for obtaining legal advice from an attorney licensed in the appropriate jurisdiction.</p>
                <p className="text-lg font-medium">COMPLIANCE</p>
                <p className="text-md">The Firm is unwilling to assume the representation of clients from those states where the marketing material does not comply with State Bar requirements and where the client is generated as a result of that communication. No representation is made or intended that the quality of the legal services to be performed is greater than the quality of legal services performed by other lawyers. In situations in which our fees are contingent or partially contingent on the outcome of the case or transaction, the client is obligated for payment of court costs and expenses regardless of outcome. Portions of this site constitute attorney advertising and marketing materials. Prior results do not guarantee a similar outcome. Each representation has unique facts and circumstances that may impact results.</p>
                <p className="text-lg font-medium">CONTENT DISCLAIMER</p>
                <p className="text-md">The content on this site is provided “as is.” We do not represent that the content will be error-free, timely, free of viruses or other harmful elements, or that defects will be corrected. Neither the Firm nor any of its affiliates, partners, or employees, makes any representations, express or implied, with respect to the timeliness, accuracy or completeness of any of the contents of this website, and expressly disclaim any liability or warranties, express or implied, including, without limitation, any implied warranties of merchantability, fitness for a particular purpose or non-infringement. The Firm expressly disclaims any liability for any action, or failure to take action, in reliance on any of the contents of the website. Your sole remedy for any dissatisfaction with any of the Contents is to refrain from using the website.</p>
                <p className="text-lg font-medium">LINKS TO OTHER SITES</p>
                <p className="text-md">This Site may include links to other Internet sites that are beyond the control of the Firm. The Firm is not responsible for the content of any such sites, makes no representations regarding any such sites, cannot guarantee their practices regarding data privacy, and does not necessarily endorse or approve of the information, material, products or services contained on or accessible through any such sites. You acknowledge and agree that your linking to other sites, your use of such sites, and your use of any information, material, products and services offered by such sites, are solely at your own risk.</p>
                <p className="text-lg font-medium">ARBITRATION</p>
                <p className="text-md">Any dispute, claim, or controversy arising out of or relating in any way to these Terms of Use or to your use of the Website will be resolved by binding. confidential arbitration in Orange County, California before an arbitrator, rather than in court. The Federal Arbitration Act and federal arbitration law apply to these Terms of Use. The arbitration shall be administered by JAMS pursuant to its Comprehensive Arbitration Rules and Procedures. There is no judge or jury in arbitration, and court review of an arbitration award is limited.  You and we each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated, or representative action.  If for any reason a claim proceeds in court rather than in arbitration, you and we each waive a right to a jury trial.  This clause shall not preclude parties from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction.</p>
                <p className="text-lg font-medium">APPLICABLE LAW</p>
                <p className="text-md">By using this Website, you agree that the Federal Arbitration Act, applicable federal law, and the laws of the state of California, without regard to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise between you and us.  To the extent any dispute, claim, or controversy proceeds in court rather than arbitration, it shall proceed in the federal or state courts of Orange County, California. You waive any objection you may have to jurisdiction and venue in such courts.</p>
                <p className="text-lg font-medium">SEVERABILITY</p>
                <p className="text-md">If anything in these Terms of Use shall be deemed invalid, void, or for any reason unenforceable, that portion shall be deemed severable and shall not affect the validity and enforcement of any remaining condition.</p>
            </div>
            {/* bit of spacing */}
            <div className="h-20"></div>
        </div >
    );
}

export default Terms;