
import React from 'react';
import { Link } from 'react-router-dom';

const HomeProject = (props) => {
    return (
        <div className="flex flex-col md:justify-start  h-80 m-2 px-4 md:m-4 w-2/3 sm:w-1/2 md:w-1/3">

            {/* image */}
            <div className="flex flex-col justify-center items-center w-full max-w-full max-h-full content-start bg-gray-200 p-4 h-3/4 ">
                {props.youTubeLink
                    ?
                    <iframe className="home-projects-card" src={props.youTubeLink} title={props.title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    :
                    props.image ? <img className="home-projects-card" src={props.image} alt={props.title} /> :

                        props.videoClip ?

                            // <video className="home-projects-card" src={props.videoClip} alt={props.title} controls autoPlay loop muted playsInline /> :
                            // same but expand when it's clicked on
                            <video className="home-projects-card" src={props.videoClip} alt={props.title} controls autoPlay loop muted playsInline /> :


                            <div className="flex flex-col justify-center items-center w-full h-full bg-black">
                                <h1 className="text-white text-xl">Coming Soon</h1>
                            </div>
                }
            </div>

            {/* text */}
            <div className="flex flex-col w-full ">
                <h1 className="text-xl text-black">{props.title}</h1>
                <h1 className="text-md text-black">{props.description}</h1>
                {/* <Link to={props.link} className="text-md text-black">{props.link}</Link> */}
            </div>
        </div>
    )
}

export default HomeProject;