import React from 'react';
import { useEffect } from 'react';


const AboutSection = (props) => {

    return (
        <div className={`flex flex-col md:flex-row w-full items-center justify-center ${props.background === "gray" ? "bg-gray-200" : ""}`}>
            <div className=' flex flex-col md:flex-row w-full items-center justify-center about-section-container  max-w-6xl'>

                {props.imageSide === "left"
                    ?
                    <div className="w-full md:w-1/2 flex justify-center items-center ">
                        <img className="mx-auto disappearing-mobile w-3/4 h-3/4" src={props.image} alt={props && props.title} />
                    </div>
                    :
                    null
                }
                <div className="w-full md:w-1/2 px-8 py-8">
                    <div className="about-section-text">
                        <h2 className="text-xl md:text-3xl font-bold text-gray-900 pb-4">{props && props.title}</h2>
                        {props && props.paragraphs.map((paragraph, index) => {
                            return <p className='pb-4 text-sm md:text-base' key={index}>{paragraph}</p>
                        })}
                    </div>
                </div>

                {props.imageSide === "right"
                    ?
                    <div className="w-full md:w-1/2 flex justify-center items-center">
                        <img className="mx-auto disappearing-mobile w-3/4 h-3/4 max-h-full" src={props && props.image} alt={props && props.title} />
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}


export default AboutSection;