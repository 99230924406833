import { InlineWidget } from 'react-calendly';

function Meet() {
    return (
        <div className="h-screen">
            <InlineWidget url="https://calendly.com/jwilbert/30min" style={{ height: '1000px' }} />
        </div >
    );
}

export default Meet;