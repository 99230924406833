import React from "react";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { InlineWidget } from 'react-calendly';
import Home from './components/Home';
import Meet from './components/Meet';
import About from './components/About';
import Terms from './components/Terms';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({
      isMenuOpen: !state.isMenuOpen
    }));
  }


  // if user clicks outside of menu, close it
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }
  render() {
    return (

      // <Router>
      <div>

        <Navbar isMenuOpen={this.state.isMenuOpen} handleClick={this.handleClick} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/contact" component={Contact} />  */}
          <Route path="/meet" element={<Meet />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <Footer />
      </div>
      // </Router>
    );
  }
}

export default App;
