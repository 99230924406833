import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="bg-gray-900  text-white w-full z-50 responsive-footer bottom-0">
            <div className="flex justify-between items-center px-4 py-2">
                <div className="flex items-center">
                    <div className="md:flex items-center">
                        <a href="https://twitter.com/joe_wilbert" target="_blank" rel="noreferrer" className="ml-4 md:text-2xl"><i className="fab fa-twitter"></i></a>
                        <a href="https://github.com/joewilbert" target="_blank" rel="noreferrer" className="ml-4 md:text-2xl"><i className="fab fa-github"></i></a>
                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <Link to="/terms" className="text-xs md:text-sm mr-4">Terms</Link>
                </div>
            </div>
            <p className="text-xs md:text-sm mr-4 text-center pb-4">© 2023 Joseph Wilbert</p>
        </div>
    );
}

export default Footer;
