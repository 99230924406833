// A react route component that renders a page with the calendly link 
// Path: src\components\Meet.jsx

import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { InlineWidget } from 'react-calendly';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import HomeProject from "./HomeProject";
import useVanta from "./hooks/useVanta";

const Home = (props) => {

    const myRefDiv = useVanta()

    return (
        <div ref={myRefDiv} className="banner-background">

            <div className="banner max-w-6xl mx-auto  ">
                <div className="banner-text-container" >
                    <div className="banner-text-left">
                        <h1 className="banner-text-coder roles-text">&lt;coder&gt;</h1>
                        <p className="banner-text-text text-center disappearing-mobile">Full-stack developer, building cutting edge AI</p>
                    </div>
                    <div className="banner-img-container">
                        <img src={require('../joe.png')} alt="your-name" className="banner-img" />
                    </div>
                    <div className="banner-text-right">
                        <h1 className="banner-text-lawyer roles-text">Lawyer.</h1>
                        <p className="banner-text-text text-center disappearing-mobile">Legal strategist, helping businesses compete and innovate</p>
                    </div>
                </div>
            </div>


            <div className="below-banner-container">
                <div className="relative">
                    <div className=" left-0 right-0 px-4 pt-16 text-center text-white z-10 max-w-4xl mx-auto">
                        <h1 className="text-xl font-medium text-black stretchy">I'm a entrepreneur, software developer, and business lawyer</h1>
                        <h2 className=" text-md text-black pt-4 pb-12  leading-6">I run a startup making <span className="accent-color">collaborative artificial intelligence agents</span>, and am a partner at the law firm

                            <a href="https://lbslawyers.com/wilbert" target="_blank" rel="noreferrer" className="accent-color"> Lopez, Bark & Schulz LLP</a>,

                            practicing tech, media, and insurance law. I help people make ideas reality and thrive. </h2>

                        <div className="flex justify-center">
                            <a href="mailto:joe@jwilbert.com" className="bg-black text-white px-4 py-2 rounded-xl">Email Me</a>


                        </div>
                    </div>

                    <div className="max-w-5xl mx-auto my-8">
                        <div className="flex justify-center py-2">
                            <div className="flex flex-col justify-center items-center md:py-4 px-4">
                                <div className="flex flex-row justify-center items-center">
                                    <hr className="center-hr" style={{ height: 5 }} />
                                    <div className="px-4 text-md font-medium text-center text-gray-600">Recent Projects</div>
                                    <hr className="center-hr" style={{ height: 5 }} />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row pb-8 justify-center items-center">
                            <HomeProject
                                title="OwnDevice"
                                description="Touchless controller app for Windows & Mac"
                                youTubeLink="https://www.youtube.com/embed/bHjj46AIVxs"
                            />
                            <HomeProject
                                title="Data Privacy"
                                description="Led compliance for leading insurance marketing company"
                                image={require("../privacy.jpg")}
                            />
                            <HomeProject
                                title="MacGPT"
                                description="Prompt GPT to generate text anywhere on your Mac"
                                videoClip={require("../gptmac.mp4")}
                            // tweet="1619008359893725184"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Home;